import React from 'react'
import './index.scss'


export default class tab3 extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div id='tab3' className="tab3">
        <div className='yj'>
          <div>愿景</div>
        </div>
      </div>
    )
  }
}
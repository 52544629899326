import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import $ from 'jquery';
import './index.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  }
}));

const LIST = [
  {
    str: '首页',
    url:'tab1'
  },
  {
    str: '业务介绍',
    url:'tab2'
  },
  {
    str: '关于晟唐',
    url:'tab3'
  }, {
    str: '联系我们',
    url:'https://openai.weixin.qq.com/webapp/3Vfr6kz3PPMqC0HKkW0AHINh6cAnHy?robotName=%E5%B0%8F%E5%94%90'
  }
]

export default function ButtonAppBar() {

  const [selectDomStr,setSelectDomStr] = useState('tab1')

  useEffect(()=>{
    $('html, body').animate({ scrollTop: $("#" + selectDomStr + "").offset().top - 98 }, 200);
  },[selectDomStr])

  const classes = useStyles();
  const jumpUrl = (url) => {
    if (url.startsWith('http')) {
      window.open(url)
    } else {
      setSelectDomStr(url)
    }
    
  }


  return (
    <div className={classes.root}>
      <AppBar position="fixed" className="appBar">
        <Toolbar>
          <Typography variant="h6" className={classes.title}></Typography>
          {LIST.map((item,i)=>(
            <span key={i} className={`${item.url===selectDomStr?'select':''}`} onClick={() => { jumpUrl(item.url) }}>{item.str}</span>
          ))}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}
import React from 'react'
import './index.scss'

export default class tab4 extends React.Component {
  render() {
    return (
      <div id='tab4' className='tab4'>
        <div className='hit'>© 2005-2022 北京迪谱信息服务有限公司版权所有，并保留所有权利。 北京市朝阳区豆各庄黄厂西路1号C3栋三层1133</div>
        <div className='hit' onClick={()=>{
          window.location.href = 'http://beian.miit.gov.cn/'
        }}>ICP备案证书号:京ICP备2024044584号-1</div>
      </div>
    )
  }
}
import React from "react";
import "./index.scss";
const list = [
  {
    name: '额度高',
    info: '最高可贷20万额度',
  },
  {
    name: '闪电放款',
    info: '2分钟审批，10秒到账',
  },
  {
    name: '易操作',
    info: '只需要身份证和银行卡即可借',
  },
  {
    name: '无套路',
    info: '息费透明，安全合规',
  }
]
export default class tab2 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="tab2" className="tab2">
        <div className="title">业务介绍</div>
        <div className="hit">全国性互联网小贷，安全合规，无套路</div>
        <div className="list-wrapper">
          {list.map((item, index) => {
            return (
              <div className="list">
                <div className="icon"></div>
                <div className="name">{ item.name}</div>
                <div className="info">{ item.info}</div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

import React from "react";
import history from '../util/history'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import Index from '../view/index'
import ButtonAppBar from '../compontent/AppBar/index'
import Footer from '../compontent/footer/index'

const routes = [
  {
    path: "/",
    component: Index
  }
];

function routers() {
  return (
    <Router history={history}>
      <React.Suspense fallback>
        {/* 导航 */}
        {/* <ButtonAppBar /> */}

        <Switch>
          {routes.map((route, index) => {
            return <Route key={index} path={route.path} component={route.component}/>
          })}
          <Redirect path="/" to="/home" />
        </Switch>
        
        <Footer />
      </React.Suspense>
    </Router>
  );
}

export default routers;
import React from 'react' 
import Tab1 from '../tab1/index.js' 
import Tab2 from '../tab2/index.js' 
import Tab3 from '../tab3/index.js' 
import Tab4 from '../tab4/index.js' 
import './index.scss'

// eslint-disable-next-line no-undef
const Index = () => {
  return <div className='box-wrapper'>
      {/* <Tab1/>
      <Tab2/> */}
      <Tab3/>
      <Tab4/>
  </div>
}

export default Index
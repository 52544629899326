import React from 'react'
import './index.scss'

export default class tab1 extends React.Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div  id='tab1' className="tab1">
        <div className='title'>全国性互联网小贷</div>
        <div className='hit'>息费透明 · 安全合规 · 无套路</div>
      </div>
    )
  }
}
import React from 'react'
import history from '../../util/history'
import './index.scss'

export default class footer extends React.Component {
  jumpUrl (url) {
    history.push('/' + url)
  }
  render() {
    return (
      <div className="footer">
      </div>
    )
  }
}